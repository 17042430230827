import React from 'react';
import Drawersuivi from '../Components/Drawersuivi/Drawersuivi';
import NavBar from '../Components/Barnav/NavBar';

const Home = () => {
    return (
        <div>
            {/* <NavBar /> */}
            <Drawersuivi/>
        </div>
    );
};

export default Home;